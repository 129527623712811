<template>
  <QuickAddToCartControls
    v-if="customerCanPurchase"
    :key="woocommerceId"
    :qty-in-cart="qtyInCart"
    :product-card-hover="productCardHover"
    @updateQty="updateQty"
  />
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    props: {
      woocommerceId: {
        type: String,
        required: true
      },
      productCardHover: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      ...mapGetters('cart', ['xShoppingCart']),
      ...mapGetters('authuser', ['xIsAuthenticated']),
      ...mapGetters('user-region', ['xSelectedLocationId']),

      customerCanPurchase() {
        return this.customerHasLocationSelected && this.xIsAuthenticated
      },
      customerHasLocationSelected() {
        return !!this.xSelectedLocationId
      },
      cartItem() {
        return this.xShoppingCart.items.find((item) => item.id.toString() === this.woocommerceId)
      },
      qtyInCart() {
        return this.cartItem ? this.cartItem.quantity.value : 0
      },
      cartKey() {
        return this.cartItem ? this.cartItem?.item_key : null
      }
    },
    methods: {
      ...mapActions('cart', ['xAddToCart', 'xRemoveFromCart', 'xUpdateProductQuantity']),

      async updateQty(qty) {
        if (qty === 0) {
          await this.xRemoveFromCart({ item_key: this.cartKey })
        } else if (this.qtyInCart === 0) {
          await this.xAddToCart({
            productID: this.woocommerceId,
            quantity: qty
          })
          this.$emit('addToCart')
        } else {
          if (qty >= this.qtyInCart) {
            this.$emit('addToCart')            
          }  
          await this.xUpdateProductQuantity({
            itemKey: this.cartKey,
            quantity: qty
          })
        }
      }
    }
  }
</script>
