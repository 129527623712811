<template>
  <NuxtLink :to="`/product/${slug}`">
    <div
      class="product-card md:px-6 cursor-pointer relative"
      @pointerenter="pointerEnter"
      @pointerleave="pointerLeave"
    >
      <div class="product-card-container w-full items-center">
        <div
          class="mr-3 h-full items-center flex"
          :class="{
            'w-3/6 md:w-auto': layoutStrategy === 'default',
            'w-auto': layoutStrategy === 'fixed'
          }"
        >
          <ProductImage
            v-if="featuredImage"
            :alt="title"
            :url="featuredImage"
            class="my-6 md:my-0"
            :class="{
              's-productImage--fixedHeight': layoutStrategy === 'fixed'
            }"
          />
        </div>

        <div class="product-card-component h-full w-3/6 ml-3 flex flex-col justify-center">
          <div v-if="sort">
            <div class="sort">{{ sort }}</div>
          </div>
          <div class="title">{{ title }}</div>
          <div class="min-h-[50px] h-[50px] 3xl:min-h-[30px] 3xl:h-[30px] mb-[0.25rem] md:mb-0">
            <div
              class="yotpo bottomLine"
              :data-yotpo-product-id="woocommerceId"
            ></div>
          </div>
          <div class="strain py-1">
            {{ category }}
            <span v-if="concentration">({{ concentration }})</span>
          </div>
          <div class="details">
            <div class="details-block">
              <div
                v-if="thc"
                class="details-title"
              >
                {{ cbdUnit }}
              </div>
            </div>
          </div>

          <div class="price">
            <div
              v-if="discountPrice !== price && discountPrice !== '' && discountPrice !== null"
              class="flex flex-row"
            >
              <div
                :class="{ 'line-through': discountPrice }"
                class="full-price"
              >
                ${{ formatPrice(price) }}
              </div>
              <div class="discount-price">${{ formatPrice(discountPrice) }}</div>
            </div>
            <div
              v-else
              class="full-price"
            >
              ${{ formatPrice(price) }}
            </div>
          </div>

          <div class="w-full flex mt-3">
            <QuickAddToCart
              :woocommerce-id="woocommerceId"
              :product-card-hover="hover"
              @addToCart="this.$emit('addToCart', title)"
            />
          </div>
        </div>
      </div>
    </div>
  </NuxtLink>
</template>

<script>
  export default {
    props: {
      woocommerceId: {
        type: String,
        required: true
      },
      sort: {
        default: null
      },
      title: {
        default: null
      },
      category: {
        default: null
      },
      thc: {
        default: null
      },
      price: {
        default: null
      },
      discountPrice: {
        default: null
      },
      images: {
        type: Array,
        default: () => []
      },
      image: {
        type: [String, Boolean],
        default: null
      },
      concentration: {
        default: null
      },
      unit: {
        default: null
      },
      slug: {
        type: String,
        required: true
      },
      layoutStrategy: {
        type: String,
        default: 'default'
      },
      isedible: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        hover: false
      }
    },
    computed: {
      featuredImage() {
        return this.image || this.images.find((image) => image.featured === 1)?.url
      },
      cbdUnit(thc) {
        if (this.thc === null || this.thc === undefined) return ''
        return this.category.includes('Edible') ? `Total THC:${this.thc} mg` : `THC:${this.thc}%`
      }
    },
    methods: {
      formatPrice(value) {
        const val = (value / 1).toFixed(2)
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      pointerEnter(event) {
        if (event.pointerType === 'touch') return
        this.hover = true
      },
      pointerLeave(event) {
        if (event.pointerType === 'touch') return
        this.hover = false
      }
    }
  }
</script>

<style scoped lang="postcss">
  .product-card-container {
    display: flex;
    @apply font-alt p-0 px-5 md:px-0;

    @media screen and (max-width: 768px) {
      align-items: center;
      flex-grow: 1;
    }
  }

  .product-card {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    @apply items-center;
  }

  .product-card-example {
    object-fit: contain;
    object-position: center;
  }

  .sort {
    background: #fdf0f6;
    border-radius: 14px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #002d52;
    display: inline;
    @apply px-3 py-1 mt-3;
  }

  .title {
    font-weight: 700;
    text-transform: uppercase;
    color: #002d52;
    @apply font-main text-lg my-3 mb-1 leading-tight;
  }

  .strain {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #7c94a5;
  }

  .details {
    margin-bottom: 9px;
    display: flex;
  }

  .details-block {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #002d52;
  }

  .details-block:first-child {
    margin-right: 16px;
  }

  .price {
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
  }

  .full-price {
    margin-right: 9px;
    color: #3fb9c5;
    @apply text-lg;
  }

  .discount-price {
    color: #fe84b8;
    @apply text-lg;
  }
</style>
